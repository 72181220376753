#navbarParentContainer {
    width: 100%;
    display: flex;
    opacity: 0%;
    animation: bringDown 2s 1s forwards;
    flex-wrap: wrap;
    overflow: hidden;
    z-index: 999;
    margin: 0;
    top: 0;
    position: sticky;
  }
  
  #navbarContainer {
    width: 100%;
    padding: 2vh;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;    
  }
  #sidebar{
    display: none;
  }
  
  #logoContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
  }
  
  #listItems{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #homeTitle {
    /* font-size: 1.2vw; */
    font-weight: 700;
  }
  .listOfBtns{
    cursor: pointer;
    position: relative;
    font-size: 18px;
    border: none;
    background-color: white;
    font-weight: 700;
  }
  html{
    scroll-behavior: smooth;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: default;
  }

  .listOfBtns::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 0;
    height: 3px;
    background-color: #00B7A9;
    transition: width 0.3s ease-in-out;
  }
  
  .listOfBtns:hover::before {
    width: 50%; 
  }
  .underline{
    color: #00B7A9;
  }
  .listOfBtns:hover {
    text-decoration: #00B7A9;
  }
  
  
  #signUpContainer {
    display: flex;
    flex-direction: column;
    padding: 1vw;
  }
  
  #getInButton {
    padding: 0.5vw;
    background-color: white;
    border: 3px solid black;
    font-size: .9vw;
  }
  
  #getInButton:hover{
    background-color: black;
    color:white;
  }
  #theJourneyText {
    font-size: 1vw;
  }
  
  @keyframes bringDown {
    from {
      top: -2vh;
      opacity: 0%;
    }
    to {
      top: 0vh;
      opacity: 100%;
    }
  }
  #ham{
    width: 7vw;
    height: 7vh;
  }
#listItemsSide{
  display: none;
  display: flex;
  flex-direction: column;
}
  @media screen and (max-width:600px) {
    .listOfBtns, #homeTitle{
      font-size: 10px;
    }
    #navbarContainer{
      display: none;
    }
    #sidebar{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }