#teamPageCard {
    /* height: 200vh; */
    background-image: url('../../assets/team.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    padding: 10vh;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

#teamHead{
    width: 50%;
    align-self: flex-end;
}

#memberCardsContainer {
    width: 90%;
    height: 70vh;
    flex-wrap: wrap;
}

.glassCardContainer {
    padding: 15%;
    height: 50vh;
    width: 50vh;
}

/* .glassCardContainer */
.activeCard {
    height: 40vh;
    width: 40vh;
    background-image: url('../../assets/glassCard.png');
    opacity: 0.8;
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1%;

    box-shadow: 0 0 30px rgba(0, 183, 169, 0.5);
    transform: scale(1.2);
    transition: transform 0.5s ease-in-out;
  }
  

#avatar {
    height: 15vh;
    width: 13vh;
    border-radius: 50%;
}

.glassCard {
    height: 35vh;
    width: 35vh;
    background-image: url('../../assets/glassCard.png');
    opacity: 0.8;
    background-repeat: no-repeat;
    color: white;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1%;
}
#avatar{
    width: 50%;
    height: 50%;
}
#nameInCard,#designationInCard{
    width: 90%;
    text-align: center;
}

#footerContainer{
    width: 100%;
}

#footer{
    color: white;
    height: 20vh;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

#faqContainer, #reachUs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    flex-wrap: wrap;
}

#faq, #reach{
    font-size: 6vh;
    font-weight: 700;
}

#reach{
    text-align: center;
}

#dropUps{
    width: 60%;
}

#dropUp{
    display: flex;
    flex-direction: column;
    font-size: 3vh;
    flex-wrap: wrap;
}

#dropUpContent{
    display: flex;
    justify-content: space-between;
    padding: 1vh;
    flex-wrap: wrap;
}

#msgFaq, #msgFaq2{
    box-shadow: 2px 2px 5px  white;
    border-radius: 20px;
    padding: 1vh;
    font-size: 16px;
}

#tri{
    height: 1.5vh;
}

#triTurn{
    height: 1.5vh;
    transform: rotate(90deg);
}

#imgsLink{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#link{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: 500;
}

#mailCont, #instaCont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
}

#reachImg{
    height: 10vh;
}
#footerContainer, #footer, #reachUs{
    height: 30vh;
}
#reachUs{
    justify-content: space-evenly;
}

@media screen and (max-width:768px){
    #teamPageCard{
        background-image: none;
        background-color: black;        
    }
    #teamHead{
        width: 100%;
    }
    #msgFaq, #msgFaq2{
        overflow: hidden;
    }
    .glassCard,.activeCard{
        width: 80%;
        flex-wrap: wrap;
    }
    #footerContainer, #footer{
        width: 100%;
        border-radius: 50px;
        padding: 10px;
        background-color: #008D86;
        display: flex;
        flex-wrap: wrap;
        height: 120vh;
    }
    #faqContainer, #reachUs, #reach{
        width: 100%;
    }
    #reachUs{
        height: 50vh;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #imgsLink{
        justify-content: space-around;
        align-items: center;
    }
}


.footerdata{
    margin-top: -10px;
    width: 100%;

    background-color: #000000;

    display: flex;

    padding: 20px;

    .leftfooter{
        padding: 10px 20px;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        h1{
            font-size: 2.3rem;
            font-weight: 700;
            color: #ffffff;
        }
        hr{
            width: 80%;
            height: 2px;
            background-color: #ffffff;
            border: none;
            margin: 10px 0px;
        }
    }

    .rightfooter{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1{
            font-size: 2.2rem;
            font-weight: 700;
            color: #ffffff;
            margin-bottom: 30px;
        }
        .footerdownload {

            display: flex;
            flex-direction: row;
            gap:50px;
            align-items: center;
            justify-content: center;
            img.qr{
                width: 150px;
                height: 150px;
            }
    
            a {
                text-decoration: none;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
    
                gap: 8px;
                background-color: #fff;
                border: none;
                border-radius: 20px;
                padding: 10px 20px;
                cursor: pointer;
    
                p {
                    font-size: 1rem;
                    font-weight: 700;
                }
    
            }
        }
    }
}