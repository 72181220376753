#workPage{
    margin: 0;
    height: auto;
    background-image: url(../../assets/howWeWork.png);
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    text-align: justify;
    padding: 2vh;
}
#headingWork{
    width: 100%;
    color: white;
    padding: 2vh;
    font-size: 20px;
}
#h1Heading{
    padding-left: 10vw;
}

.fade-in-element{
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


#con1, #con2{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

@keyframes appear {
    from{
        opacity: 0%;
    }
    to{
        opacity: 100%;
    }
}

@keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-element {
    opacity: 0;
    animation: fade-in 1s ease-out forwards;
    display: flex;
  }
  

  .containers{
       text-align: -webkit-center;   
       color: white;   
       opacity: 0;   
       transition: opacity 0.8s ease-in-out; 
    }  
  .containers.show {   
    transform: translateY(1%);   
    opacity: 1; 
}
#frame{
    width: 30vh;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px solid #2ab7bcbd;
    border-radius: 10px;
    background-image: radial-gradient(circle, transparent 50%, #2ab7bc78 );
    margin: 1vh;
}
.imgTag{
    width: 70%;
}

@media screen and (max-width : 768px) {
    #con1, #con2{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    #frame, .imgTag{
        width: 40%;
    }
    #uaImg, #daImg, #saImg{
        transform: rotate(90deg);
    }
}