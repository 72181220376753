#signUpPage{
    display: flex;
    
    background-color: #008D86;
    height:83vh;
    overflow: hidden;
}

#pictureContainer{
    /* height: 100vh; */
    width: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

#detailsContainer{
    /* height: 90vh; */
    width: 50%;
    background-color: white;
    border-radius: 30% 0 0 0;
    overflow: hidden;
}

#innerContanier{
    padding: 10vh 0 0 10vw;
    width: 65%;
    text-align: -webkit-center;
}
  
#txt{
    font-size: 2em;
    color: #008D86;
    font-weight: 700;
}

#inputs{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.inputField{
    padding: 10px 0;
    text-decoration: none;
    border: none;
    outline: none;
    border-bottom: 2px solid #008D86;
    margin: 3%;
}

/* #tnc{
    display: flex;
} */

#signUpBtn{
    color: white;
    padding: 2vh;
    background-color: #008D86;
    border-radius: 20px;
    border: none;
}

/*
#horizontalComponent{
    width: 50%; 
    height: 20px; 
    border-bottom: 1px solid black; 
    text-align:  center; 
}

#spanSection{
    font-size: 20; 
    background-color: white; 
    color: rgb(153, 150, 150);
    font-weight: 700;
    padding: 0 10px;
}

  #otherHalf{
    padding: 20px;
}

#google{
    position: relative;
    left: 20%;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid grey;
    padding: 8px;
    color: red;
    font-weight: 700;
}

#alreadyUser{
    display: flex;
    padding: 3%;
}

#signInBtn{
    background-color: #fff;
    border: none;
    color: #008D86;
    font-weight: 600;
} */
#signInBtn{
    background-color: #fff;
    border: none;
    color: #008D86;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    #signUpPage{
        background-color: #fff;
        align-items: center;
    justify-content: center;
    }
    #pictureContainer{
        display: none;
    }
    #inputs{
        width: 80%;
    }
  }
  