#whyUsPage {
    font-family: sans-serif;
    text-align: center;
    padding: 10%;

    background-image: url('../../assets/whyUsPic.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #00B7A9;
    scroll-behavior:smooth;
}

#mainHead {
    color: white;
    text-align-last: left;
    color: #00B7A9;
}

#lhs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
}

h2 {
    transition: font-size 0.5s ease-in-out;
    font-size: 22px;
}

h2.enlarged {
    font-size: 28px;
    font-weight: 700;
}

.main {
    display: flex;
    justify-content: space-around;
    width: 40%;
}

.photo {
    width: 20%;
    height: 20%;
    flex: 1;
    margin-left: 2rem;
}

#scrollContainer {
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    padding: 0 7vh 0 ;
    scroll-behavior: smooth;
}

#scrollContainer::-webkit-scrollbar {
    width: 10px;
}

#scrollContainer::-webkit-scrollbar-thumb {
    background-color: #00B7A9;
    border-radius: 20px;
    display: none;
}

#scrollContainer img {
    width: 30vw;
    height: 50vh;
    scroll-snap-align: start;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    cursor: pointer;
    padding-bottom: 2vh;
}

#scrollContainer img.active {
    opacity: 1;
}

#bodyWhy {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

#bodyWhy .main {
    margin-right: 2rem;
}

.text {
    margin-top: 20px;
    width: 40%;
    display: flex;
    flex-direction: column;
}

#lhs h1 {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

#lhs h1:hover {
    color: #00B7A9;
}

#tenantNhost h1.activePerson {
    border-bottom: 3px solid #00B7A9;
    color: white;
    font-size: 30px;
    animation: anim 0.5s ease-in-out;
  }
  @keyframes anim {
    0%{
        border-bottom: none;
        font-size: 24px;
    }
    100%{
        border-bottom: 3px solid #00B7A9;
        font-size: 30px;
    }
  }
#headingTNH{
    color: lightgray;
}

#titleContainer{
    text-align: left;
}

@media screen and (max-width:768px){
    #whyUsPage{
        font-size: 8px;
    }
    h2.enlarged{
        font-size: 16px;
    }
    #scrollContainer, #scrollContainer img{
        height: 10vh;
        width: auto;
    }
}