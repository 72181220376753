.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.notificationbox {
  position: relative;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  top: -25px;
  left: 0;
  width: 100%;
  background-color: #03b5aa;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  z-index: 1;
}
.notificationbox .imgbox {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.notificationbox .imgbox img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.notificationbox .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.notificationbox .content .footerdownload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notificationbox .content .footerdownload h1 {
  width: 100%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 10px 0px 5px 0px;
}
.notificationbox .content .footerdownload a {
  text-decoration: none;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}
.notificationbox .content .footerdownload a p {
  font-size: 1rem;
  font-weight: 700;
}/*# sourceMappingURL=Slider.css.map */