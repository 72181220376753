@keyframes bgchange {
    0%{
        background-image: url(../../assets/triangleBlackBg.png);
    }
    15%{
      background-image: url(../../assets/triangleBg.png);
    }
    50%{
      background-image: url(../../assets/triangleBlackBg.png);
    }
    100%{
      background-image: url(../../assets/triangleBg.png);
    }
}


@keyframes textAnimation {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated-text {
    animation: textAnimation 2s ease-in-out;
  }
  
  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  
#headingTri{
  color: white;
  width: 30%;
}
#claim{
  width: 60%;
}
#headTriCont{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
#claimPage{
  width: 100%;
  height: 120vh;
  display: flex;
  flex-wrap: wrap;
}
#claimContent{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
#triangle{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#imgss{
  height: 50vh;
  overflow-y: scroll;
  width: 30%;
}

#imgRot{
  width: 50vh;
  height: 50vh;
}
#imgss::-webkit-scrollbar{
  display: none;
}
@media screen and (max-width:600px) {
  #imgRot{
    width: 30vw;
    height: 30vh;
  }
  #headTriCont{
    width: 100%;
  }
  #headingTri, #claim{
    width: 50%;
  }
}