.ack{
    position: relative;
    top:100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;

}

.ack img{
        width: 80%;
        height: 300px;
}

.ack h1{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.ack h2{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
}

.ack a{
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;
}