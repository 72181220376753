#ackContainer{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#mailPic{
    height : 50vh;
    width: 30vw;
}
#txtMail{
    font-size: 30px;
}