.navbar{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: rgb(255, 255, 255);
    border-bottom:2px solid  #03B5AA;

    padding: 0px 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    a{
        user-select: none;
        text-decoration: none;
        color: black;
    }
    .logobox{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        user-select: none;
        img.logo{
            width: 40px;
            height: 40px;
        }
        .site-namebox{
            p{  
                position: relative;
                top:48px;
                left:-2px;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 30px; 
                color: black;
            }

            h3{
                font-size: 0.55rem;
                font-weight: 300;
                line-height: 5px;
            }
        }
    }

    .menubox{
        user-select: none;
        cursor: pointer;
        z-index: 1001;

    }

    .navlinks{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        padding: 0px 30px;
        background-color: rgba(255, 255, 255, 0.98);
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        transition: all .5s ease-in-out;
        transform: translateX(-100%);

        a{
            text-decoration: none;
            color: black;
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 35px;
            margin: 10px 0px;

            &:hover{
                scale: 1.1;
                color: #03b5aa;
            }

            &.nav-btn{
                margin-top: 30px;
                text-align: center;
                width: 100%;
                background-color: #000000;
                color: white;
                padding: 10px 10px;
                border-radius: 5px;
                font-size: 1rem;
                font-weight: 500;
                line-height: 30px;
                transition: all .3s ease-in-out;
                border-radius: 15px;

                &:hover{
                    background-color: #03b5aa;
                    color: white;
                    scale: 1.1;
                }
            }
        }

        &.active{
            transform: translateX(0%);
        }
    }


}