#joinUsContainer{
    height: 120vh;
    background-image: url(../../assets/joinUsBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

#joinUs{
    width: 30%;
    height: 12%;
    color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    background-image: radial-gradient( #2ab7bcbd,rgba(211, 211, 211, 0.100));
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh;
}

#joinUs:hover{
    transform:translateY(-30px);
    transition: transform 0.5s ease-in-out ;
}
#joinuscontent{
    text-align: center;
    padding-top: 5vh;
    font-size: 20px;
    color: white;
}