#landingPage{
  height: 82vh;
  /* width: 98vw; */
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-image: url('../src/assets/newBg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

#headingContainer {
  width: 30%;
  text-align: justify;
  position: absolute;
  /* top: 20vh; */
  left: 10vh;
  animation: slide-in-left 2s 1 forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

#content{
  width: 100%;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    opacity: 100%;
    transform: translateX(0%);
  }
}



/* MobileView */

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
}

.center {
  min-width: 400px;
  position: relative;
  margin: 0 auto;
}/*# sourceMappingURL=App.css.map */