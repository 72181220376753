.privacydata {
  position: relative;
  top: 90px;
  padding: 20px;
}
.privacydata h1 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.privacydata h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.privacydata h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.privacydata p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.privacydata li {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  list-style: none;
}/*# sourceMappingURL=Privacy.css.map */